import { BaseApiService } from './BaseApiService';

class BurnFeeApiService extends BaseApiService {
  constructor(access = '', onError = null) {
    super('/api/burn/fee/', access, onError);
  }
  
}

export default BurnFeeApiService;
